<template>
  <div id="indexPage" v-cloak>
    <div class="index-main" :class="{ on: isleftClose }">
      <div class="left">
        <div v-if="!isShowReportInfo" class="left-center">
          <div class="left-center-main">
            <div class="search">
              <input type="text" placeholder="搜索关键词" v-model="keywords" />
              <p @click="searchKeyword"></p>
            </div>
            <div class="public-item">
              <p class="title">区域</p>
              <select v-model="province" @change="selectProvince">
                <option value="">--请选择--</option>
                <option
                  v-for="(item, index) in provinceArr"
                  :key="index"
                  :value="item.name"
                  >{{ item.name }}</option
                >
              </select>
              <select v-model="city" @change="selectCity">
                <option value="">--请选择--</option>
                <option
                  v-for="(item, index) in cityArr"
                  :key="index"
                  :value="item.name"
                  >{{ item.name }}</option
                >
              </select>
              <select v-model="area" @change="selectArea">
                <option value="">--请选择--</option>
                <option
                  v-for="(item, index) in areaArr"
                  :key="index"
                  :value="item.name"
                  >{{ item.name }}</option
                >
              </select>
            </div>
            <div class="public-item">
              <p class="title">类型</p>
              <div class="label-box">
                <label
                  v-for="(item, index) in alltypeArr"
                  :key="index"
                  class="public-radio"
                >
                  <input
                    type="checkbox"
                    name="category"
                    :value="item.ID"
                    v-model="type"
                    @change="pickerType"
                  />
                  <span v-text="item.Title"></span>
                </label>
              </div>
            </div>
            <div class="state-list">
              <ul>
                <li @click="updateMarkers(0)" class="">
                  <p class="p1">污染点</p>
                  <p class="p2">{{ state.all }}个</p>
                </li>
                <li @click="updateMarkers(2)" class="">
                  <p class="p1">已整治</p>
                  <p class="p2">{{ state.deal }}个</p>
                </li>
                <li @click="updateMarkers(3)">
                  <p class="p1">整治中</p>
                  <p class="p2">{{ state.valid }}个</p>
                </li>
                <li @click="updateMarkers(1)">
                  <p class="p1">未整治</p>
                  <p class="p2">{{ state.udeal }}个</p>
                </li>
              </ul>
            </div>
            <p class="tips">让每个人的家乡环境都得到保护</p>
            <!-- <p class="tips">上传身边的污染问题，让环境更清洁</p> -->
            <!-- class="public-btn goto-participate" -->
            <router-link class="public-btn" :to="{ path: '/participate' }"
              >我要参与</router-link
            >
          </div>
        </div>
        <div v-else class="report-info">
          <div class="report-info-main">
            <p class="title">
              <span>污染点详情</span>
              <i @click="closeReporteInfo"></i>
            </p>
            <div class="public-item">
              <div class="box">
                <p>名称：</p>
                <p>{{ reporteInfo.Title }}</p>
              </div>
            </div>
            <div class="public-item">
              <div class="box">
                <p>地点：</p>
                <p>{{ reporteInfo.Address }}</p>
              </div>
            </div>
            <div class="public-item">
              <div class="box">
                <p>污染类型：</p>
                <p>{{ reporteInfo.TypeName }}</p>
              </div>
            </div>
            <div class="public-item" v-show="reporteInfo.Type">
              <div class="box">
                <p>时间：</p>
                <p>发现时间：{{ reporteInfo.FindingTime }}</p>
              </div>
            </div>
            <div class="public-item">
              <div class="box">
                <p>现场描述：</p>
                <p>{{ reporteInfo.ReportInfo }}</p>
              </div>
            </div>
            <div class="public-item">
              <div class="box">
                <p>现场环境照片：</p>
                <ul v-viewer>
                  <template v-for="(item, index) in reporteInfo.ImageList">
                    <li :key="index" v-if="index < 3">
                      <img
                        :src="item.name ? item.name : item"
                        :title="item.value ? item.value : item"
                        :alt="item.value ? item.value : item"
                      />
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="public-item" v-show="reporteInfo.Type">
              <div class="box">
                <p>整治后照片：</p>
                <ul v-viewer>
                  <template v-for="(item, index) in reporteInfo.ImageListOk">
                    <li :key="index" v-if="index < 3">
                      <img
                        :src="item.name ? item.name : item"
                        :title="item.value ? item.value : item"
                        :alt="item.value ? item.value : item"
                      /></li
                  ></template>
                </ul>
              </div>
            </div>
            <!-- <router-link
              class="public-btn"
              :to="{
                path: '/submitInfo',
                query: { id: reporteInfo.ID, type: reporteInfo.Type }
              }"
              >查看进展</router-link
            > -->
            <a
              class="public-btn"
              :href="
                `/#/submitInfo?id=${reporteInfo.ID}&type=${reporteInfo.Type}`
              "
              target="_blank"
              >查看进展</a
            >
          </div>
        </div>
      </div>
      <div class="amap-wrapper">
        <indexamap
          a-map-key="7611f567fdc3d9fb52b80f0dc85e7236"
          :markers="markers"
          v-on:reportInfo="getReporInfo"
          v-on:isShowReportInfo="getIsShowReportInfo"
          :mapType="mapType"
        />
        <p class="copy-right">
          天津滨海环保咨询服务中心版权所有，并保留所有权利 备案号
          <a target="_blank" href="http://beian.miit.gov.cn/" class="beian"
            >津ICP备14005196号-2</a
          >
          |<a href="/class/10">免责声明</a>
        </p>
        <div class="small-program">
          <img src="../../assets/52.jpg" />
          <!-- <p>微信扫码便捷查看</p> -->
        </div>
      </div>
      <p class="left-handle" @click="leftHandle"></p>
    </div>
  </div>
</template>

<script>
import indexamap from "../../components/indexMap";
import testData from "./test.js";
import { mapState } from "vuex";
import axios from "@/axios-init";
export default {
  data() {
    return {
      markers: [],
      alltypeArr: [],
      state: {
        all: "",
        udeal: "",
        deal: "",
        valid: ""
      },
      isleftClose: false,
      keywords: "",
      provinceArr: [],
      province: "",
      cityArr: [],
      city: "",
      areaArr: [],
      area: "",
      type: [],
      isShowReportInfo: false,
      reporteInfo: {
        ID: "",
        Title: "",
        Address: "",
        TypeName: "",
        ReportInfo: "",
        ImageList: [],
        ImageListOk: [],
        Type: "",
        thatHandleState: 0
      },
      mapType: false
    };
  },
  computed: mapState(["user"]),
  components: {
    indexamap
  },
  async mounted() {
    try {
      // 获取污染类型
      let response = await this.$http.get("report/api/getalltype");
      if (response.data.state) {
        this.alltypeArr = response.data.data;
        response.data.data.forEach(element => {
          this.type.push(element.ID);
        });
      }

      // 获取高德地址信息列表
      response = await this.$http.get("report/api/GetAddressListNew");
      if (response.data.state) {
        response.data.data.forEach(element => {
          this.provinceArr.push(element);
        });
      }

      this.updateMarkers();
      // this.updateState();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    closeReporteInfo() {
      this.isShowReportInfo = false;
    },
    getReporInfo(data, type) {
      // console.log(data, type);
      // 改版 type->1 新版数据  type->0 老版数据
      type = Number(type);
      this.reporteInfo.ID = data.ID;
      this.reporteInfo.Title = data.Title;
      this.reporteInfo.Address = data.Address;
      this.reporteInfo.TypeName = type ? data.PolluteTypeName : data.TypeName; // 污染类型
      this.reporteInfo.FindingTime = type ? data.FindingTime : ""; // 发现时间
      this.reporteInfo.ReportInfo = type ? data.Description : data.ReportInfo; // 现场描述
      let newImage = []; // 现场环境图片
      let ImageListOk = []; // 整治后图片
      // console.log(type);
      if (type === 1) {
        data.reportImgs.map(e => {
          if (e.Type === 1) {
            newImage.push({ name: e.BigImgUrl, value: e.Remark });
          }
          if (e.Type === 2) {
            ImageListOk.push({ name: e.BigImgUrl, value: e.Remark });
          }
        });
      }
      this.reporteInfo.ImageList = type ? newImage : data.ImageList; // 现场环境图片
      this.reporteInfo.ImageListOk = ImageListOk;
      this.reporteInfo.Type = type;
    },
    getIsShowReportInfo(data) {
      // console.log(data);
      this.isShowReportInfo = data;
    },
    leftHandle() {
      this.isleftClose = !this.isleftClose;
    },
    // // 更新状态
    // updateState() {
    //   // 获取状态
    //   this.$http
    //     .get("report/api/GetPreCount", {
    //       params: {
    //         keywords: this.keywords,
    //         province: this.province,
    //         city: this.city,
    //         area: this.area,
    //         type: this.type.join(",")
    //       }
    //     })
    //     .then(response => {
    //       if (response.data.state) {
    //         let all = response.data.data.filter(m => {
    //           return m.State !== 0 && m.State !== 2;
    //         });
    //         let udeal = response.data.data.filter(m => {
    //           return m.State === 1 || m.State === 3;
    //         });
    //         let deal = response.data.data.filter(m => {
    //           return (
    //             m.State === 4 || m.State === 5 || m.State === 6 || m.State === 7
    //           );
    //         });
    //         let valid = response.data.data.filter(m => {
    //           return m.State === 5 || m.State === 6 || m.State === 7;
    //         });
    //         this.state.all = all.reduce((total, num) => {
    //           return total + num.Num;
    //         }, 0);
    //         this.state.udeal = udeal.reduce((total, num) => {
    //           return total + num.Num;
    //         }, 0);
    //         this.state.deal = deal.reduce((total, num) => {
    //           return total + num.Num;
    //         }, 0);
    //         this.state.valid = valid.reduce((total, num) => {
    //           return total + num.Num;
    //         }, 0);
    //       } else {
    //         console.log(response.data.message);
    //       }
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //     });
    // },
    // 更新标记
    updateMarkers(HandleState = 0) {
      this.mapType = false;
      let that = this;
      this.thatHandleState =
        this.thatHandleState === HandleState ? 0 : HandleState;
      console.log(this.thatHandleState);
      // 获取标记点
      this.$http
        .get("report/api/GetTopReportList", {
          params: {
            top: 0,
            account: this.user.account,
            keywords: this.keywords,
            province: this.province,
            city: this.city,
            area: this.area,
            type: this.type.join(","),
            HandleState: this.thatHandleState
          }
        })
        .then(response => {
          if (response.data) {
            // console.log(response.data);
            // 状态:0平台未审核,1平台已通过,2平台未通过,3已提交政府,4政府已处理,5志愿者已回访,6验证已通过,7验证未通过
            // const num = [0, 3, 1, 0, 40, 0, 23, 0];
            const num = response.data.data.count;

            // 所有的点数据
            this.markers = [];
            // this.markers = [
            //   ...response.data.data,
            //   ...[
            //     {
            //       ID: 13,
            //       Title: "丘测试2-颜色异常坑塘eee",
            //       Latitude: "38.219142",
            //       Longitude: "116.351903",
            //       Status: 1,
            //       Icon: "/Uploads/Report/Type/20201127175724image/11.png",
            //       UserAccount: "",
            //       Type: "1"
            //     },
            //     {
            //       ID: 9,
            //       Title: "王-测试-第二次 垃圾坑塘",
            //       Latitude: "",
            //       Longitude: "",
            //       Status: 0,
            //       Icon:
            //         "/Uploads/Report/Type/20191012164330image/shenghuolaji1.png",
            //       UserAccount: "",
            //       Type: "1"
            //     },
            //     {
            //       ID: 8,
            //       Title: "河北省邢台市隆尧县莲子镇镇东店马村黑臭坑塘",
            //       Latitude: "37.306376",
            //       Longitude: "114.873863",
            //       Status: 0,
            //       Icon: "/Uploads/Report/Type/20201127175724image/12.png",
            //       UserAccount: "",
            //       Type: "1"
            //     }
            //   ]
            // ];
            this.markers = response.data.data.list;
            // this.markers = testData.data.list;
            console.log(testData.data.list);
            // console.log(num);
            //状态:0平台未审核,1平台已通过,2平台未通过,3已提交政府,4政府已处理,5志愿者已回访,6验证已通过,7验证未通过
            let all = num.filter((e, i) => {
              // console.log(e, i);
              // return i !== 0 && i !== 2;
              return i !== 2 || i !== 7;
            });
            // 未整治
            let udeal = num.filter((e, i) => {
              return i === 1;
              //   return i === 1 || i === 3;
            });
            // 已整治
            let deal = num.filter((e, i) => {
              return i === 4 || i === 5 || i === 6;
              //   return i === 4 || i === 5 || i === 6 || i === 7;
            });
            // 整治中
            let valid = num.filter((e, i) => {
              return i === 3;
              //   return i === 5 || i === 6 || i === 7;
            });

            // console.log(all, udeal, deal, valid);

            // state: {
            //   all: "",
            //   udeal: "",
            //   deal: "",
            //   valid: ""
            // },
            this.state.all = all.reduce(this.countNum);
            this.state.udeal = udeal.reduce(this.countNum);
            this.state.deal = deal.reduce(this.countNum);
            this.state.valid = valid.reduce(this.countNum);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    countNum(total, num) {
      return total + num;
    },
    selectProvince() {
      this.cityArr = [];
      this.areaArr = [];
      this.city = "";
      this.area = "";
      this.provinceArr.forEach(element => {
        if (element.name === this.province) {
          element.districts.forEach(e => {
            this.cityArr.push(e);
          });
        }
      });
      this.mapType = true;
      this.updateMarkers();
      // this.updateState();
    },
    selectCity() {
      this.areaArr = [];
      this.area = "";
      this.cityArr.forEach(element => {
        if (element.name === this.city) {
          element.districts.forEach(e => {
            this.areaArr.push(e);
          });
        }
      });
      this.updateMarkers();
      // this.updateState();
    },
    selectArea() {
      this.updateMarkers();
      // this.updateState();
    },
    searchKeyword() {
      this.updateMarkers();
      // this.updateState();
    },
    pickerType() {
      // console.log(this.type);
      this.updateMarkers();
      // this.updateState();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === "/participate" && this.user.account === "") {
      location.href =
        axios.defaults.baseURL.replace(/\/*$/, "") + "/member/user/login";
      return;
    }
    next();
  }
};
</script>

<style lang="less" scoped>
#indexPage {
  width: 100%;
  height: calc(~"100vh - 85px");
  background-color: #f8f9fd;
  overflow: hidden;
  position: fixed;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 0;
  .index-main {
    width: 94%;
    height: auto;
    margin: 0 auto;
    position: absolute;
    left: 0;
    top: 18px;
    bottom: 18px;
    right: 0;
    display: flex;
    justify-content: space-between;
    .left {
      width: 404px;
      height: 100%;
      position: relative;
      transition: width 0.2s;
      z-index: 33;
      .left-center {
        width: 100%;
        height: 100%;
        max-height: 100%;
        background: #fff url("../../assets/18.jpg") center bottom no-repeat;
        overflow: auto;
        .left-center-main {
          width: 348px;
          margin: 0 auto;
          .search {
            position: relative;
            margin-top: 20px;
            input {
              width: 100%;
              height: 50px;
              border: 1px solid #f5f5f5;
              box-sizing: border-box;
              padding-left: 25px;
              padding-right: 60px;
            }
            p {
              width: 50px;
              height: 50px;
              position: absolute;
              right: 0;
              top: 0;
              background: url("../../assets/11.png") center center no-repeat;
              cursor: pointer;
            }
          }
          .public-item {
            padding-left: 58px;
            .title {
              font-size: 16px;
              color: #206ab0;
              margin-top: 7%;
            }
            &:nth-child(2) {
              background: url("../../assets/icon/b-point.png") 30px 2px
                no-repeat;
            }
            &:nth-child(3) {
              background: url("../../assets/icon/b-box.png") 30px 4px no-repeat;
            }
            .label-box {
              margin-top: 3%;
              display: flex;
              flex-wrap: wrap;
            }
            select {
              background-color: #f8f9fd;
              border: none;
              font-size: 14px;
              color: #666666;
              padding: 5px 6px;
              margin-top: 3%;
              border-radius: 4px;
              margin-right: 6px;
              width: auto;
            }
          }
          .state-list {
            border: 1px solid #f5f5f5;
            margin-top: 6%;
            background-color: #fff;
            ul {
              padding: 6% 0;
              li {
                cursor: pointer;
                margin-top: 3%;
                padding-left: 55px;
                position: relative;
                &.on::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  left: 0;
                  bottom: 0;
                  opacity: 0.5;
                  background: #effce7;
                }
                .p1 {
                  font-size: 16px;
                  color: #206ab0;
                }
                .p2 {
                  font-size: 16px;
                  color: #333333;
                }
                &:nth-child(1) {
                  background: url("../../assets/icon/icon-wurandian.png") 20px
                    0px no-repeat;
                }
                &:nth-child(2) {
                  background: url("../../assets/icon/icon-yizhengzhi.png") 20px
                    0px no-repeat;
                }
                &:nth-child(3) {
                  background: url("../../assets/icon/icon-zhengzhizhong.png")
                    20px 0px no-repeat;
                }
                &:nth-child(4) {
                  background: url("../../assets/icon/icon-weizhengzhi.png") 20px
                    0px no-repeat;
                }
              }
            }
          }
          .tips {
            text-align: center;
            font-size: 18px;
            color: #333333;
            padding: 12% 0 4% 0;
          }
          .goto-participate {
            margin: 0 auto 20px;
            position: relative;
            &::before {
              content: "";
              display: inline-block;
              width: 34px;
              height: 34px;
              background: #367c64 url("../../assets/19.png") center center
                no-repeat;
              border-radius: 50%;
              position: absolute;
              right: 4px;
              top: 3px;
            }
          }
        }
      }
      .report-info {
        width: 100%;
        height: 100%;
        max-height: 100%;
        background-color: #fff;
        overflow: auto;
        .report-info-main {
          padding-top: 20px;
          width: 348px;
          margin: 0 auto;
          .title {
            display: flex;
            justify-content: space-between;
            height: 38px;
            align-items: center;
            span {
              font-size: 20px;
              // border-bottom: 1px solid #206ab0;
              display: inline-block;
              height: 100%;
              line-height: 38px;
              font-weight: bold;
              background: linear-gradient(to right, #46a2da 0%, #79bc53 100%);
              -webkit-background-clip: text;
              color: transparent;
            }
            i {
              display: inline-block;
              width: 22px;
              height: 15px;
              background: url("../../assets/icon/fanhui.png") no-repeat;
              cursor: pointer;
            }
          }
          .public-item {
            margin-top: 20px;
            .box {
              width: calc(~"100% - 30px");
              padding-left: 30px;
              p {
                &:nth-child(1) {
                  font-size: 16px;
                  color: #206ab0;
                }
                &:nth-child(2) {
                  font-size: 14px;
                  color: #777777;
                  margin-top: 6px;
                }
              }
              ul {
                display: flex;
                flex-wrap: wrap;
                li {
                  width: 99px;
                  height: 99px;
                  border-radius: 6px;
                  overflow: hidden;
                  margin-right: 10px;
                  margin-top: 10px;
                  &:nth-child(3n) {
                    margin-right: 0px;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            &:nth-child(2) {
              background: url("../../assets/icon/b-mingcheng.png") 0px 2px
                no-repeat;
            }
            &:nth-child(3) {
              background: url("../../assets/icon/b-didian.png") no-repeat;
            }
            &:nth-child(4) {
              background: url("../../assets/icon/b-box.png") no-repeat;
            }
            &:nth-child(5) {
              background: url("../../assets/icon/b-shijian.png") no-repeat;
            }
            &:nth-child(6) {
              background: url("../../assets/icon/b-miaoshu.png") 0px 3px
                no-repeat;
            }
            &:nth-child(7) {
              background: url("../../assets/icon/b-img.png") 0px 3px no-repeat;
            }
            &:nth-child(8) {
              background: url("../../assets/icon/b-img.png") 0px 3px no-repeat;
            }
          }
        }
      }
      /*滚动条样式*/
      .left-center::-webkit-scrollbar,
      .report-info::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
      }
      .left-center::-webkit-scrollbar-thumb,
      .report-info::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }
    }
    .amap-wrapper {
      width: calc(~"100% - 428px");
      height: 100%;
      transition: width 0.2s;
      position: relative;
      .copy-right {
        font-size: 14px;
        color: #666666;
        position: absolute;
        left: 6px;
        bottom: 4px;
        a {
          font-size: 14px;
          color: #4393ca;
        }
      }
      .small-program {
        width: auto;
        position: absolute;
        right: 16px;
        bottom: 284px;
        z-index: 999;
        overflow: hidden;
        text-align: center;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        border-radius: 6px;
        img {
          width: 60px;
          height: 60px;
          transition: all 0.3s;
        }
        p {
          position: relative;
          top: -4px;
          width: 100%;
          background-color: #fff;
          font-size: 12px;
          padding: 4px 0px;
          text-align: center;
          letter-spacing: 3px;
        }
        &:hover {
          img {
            width: 172px;
            height: 172px;
          }
        }
      }
    }
    .left-handle {
      position: absolute;
      left: 410px;
      top: 50%;
      transform: translateX(-50%);
      width: 10px;
      height: 74px;
      z-index: 3;
      background: url("../../assets/49.png") no-repeat;
      border-radius: 10px;
      cursor: pointer;
      margin-top: -18px;
    }
    &.on {
      .left {
        width: 0px;
      }
      .amap-wrapper {
        width: calc(100% - 16px);
      }
      .left-handle {
        left: 0;
      }
    }
  }
}
.public-radio {
  margin-top: 2%;
  span::before {
    border: none;
    background-color: #e5e5e5;
  }
}
.public-radio span::before {
  // border-radius: 0;
}
.public-btn {
  background: linear-gradient(to right, #46a2da 0%, #79bc53 100%);
}
</style>
