<template>
  <div id="containerView">
    <div id="container"></div>
    <img
      id="tulip"
      src="../../assets/icon/icon-point.png"
      alt="The Tulip"
      style="opacity: 0;position: absolute;top: 0;left: 0;"
    />
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
let AMapUI;
let _VueAMap;
let pointSimplifierIns;
let distCluster;
let _markers;

// let mapType = false;

let that;

let groupStyleMap = {};

export default {
  name: "AMap",
  props: {
    aMapKey: String,
    markers: {
      type: Array,
      default: () => []
    },
    mapType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      Vue_VueAMap: null,
      newData: null
    };
  },
  mounted() {
    window.viewInfo = this.viewInfo.bind(this);
    window.closeInfoWindow = this.closeInfoWindow.bind(this);

    that = this;
    if (_markers) {
      this.creatMap();
    }
  },
  methods: {
    viewInfo() {
      this.$emit("isShowReportInfo", true);
    },
    closeInfoWindow() {
      _VueAMap.clearInfoWindow();
    },
    creatMap(mapType) {
      AMapLoader.load({
        key: `${this.aMapKey}`, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ControlBar",
          "AMap.ToolBar",
          "AMap.LngLat",
          "AMap.MapType"
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [] // 需要加载的 AMapUI ui插件
          // plugins: ["geo/DistrictCluster", "misc/PointSimplifier", "lib/utils"] // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: "1.3.2" // Loca 版本，缺省 1.3.2
        }
      })
        .then(
          (this.Vue_VueAMap = AMap => {
            _VueAMap = new AMap.Map("container", {
              zoom: 3,
              features: ["bg", "road", "building"]
            });
            // map.addControl(
            //   new AMap.ControlBar({
            //     bottom: "30px",
            //     right: "20px"
            //   })
            // );
            _VueAMap.addControl(
              new AMap.ToolBar({
                position: "RB"
              })
            );
            _VueAMap.addControl(
              new AMap.MapType({
                defaultType: 0 //0代表默认，1代表卫星
              })
            );
            AMapUI = window.AMapUI;
            // AMap = window.AMap;

            function initPage(DistrictCluster, PointSimplifier, utils) {
              //自定义绘制区域聚合
              function MyRender() {
                // function MyRender(distClusterIns, opts) {
                // console.log(distClusterIns, opts);
                //直接调用父类的构造函数
                MyRender.__super__.constructor.apply(this, arguments);
              }
              //继承默认引擎
              utils.inherit(MyRender, DistrictCluster.Render.Default);

              utils.extend(MyRender.prototype, {
                drawFeaturePolygons: function(
                  ctx,
                  polygons,
                  styleOptions,
                  feature,
                  dataItems
                ) {
                  //调用父类方法
                  MyRender.__super__.drawFeaturePolygons.apply(this, arguments);

                  //直接绘制聚合信息
                  this.drawMyLabel(feature, dataItems);
                },
                _initContainter: function() {
                  //调用父类方法
                  MyRender.__super__._initContainter.apply(this, arguments);

                  //创建一个新的canvas
                  this._createCanvas("mylabel", this._container);
                },
                /**
                 * 绘制一个label替代聚合标注
                 */
                drawMyLabel: function(feature, dataItems) {
                  if (dataItems.length > 0) {
                    var pixelRatio = this.getPixelRatio(); //高清下存在比例放大

                    var containerPos = _VueAMap.lngLatToContainer(
                      feature.properties.centroid || feature.properties.center
                    );

                    var labelCtx = this._getCanvasCxt("mylabel");

                    //文字的中心点
                    var centerX = containerPos.getX() * pixelRatio,
                      centerY = containerPos.getY() * pixelRatio;

                    labelCtx.save();

                    var text = feature.properties.name;

                    //计算文字坐标偏移量
                    let Xoffset = 0;
                    // console.log(dataItems.length.toString().length);
                    if (dataItems.length > 0) {
                      text += "（" + dataItems.length + "）";

                      Xoffset =
                        (dataItems.length.toString().length +
                          2 * 16 * pixelRatio) /
                        2;
                    }
                    // console.log(Xoffset);

                    var textMetrics = labelCtx.measureText(text);

                    var halfTxtWidth = textMetrics.width / 2;

                    labelCtx.font = 16 * pixelRatio + "px 微软雅黑";
                    labelCtx.fillStyle = "#216bb0";
                    labelCtx.textBaseline = "middle";
                    labelCtx.strokeStyle = "#ffffff";
                    labelCtx.lineWidth = 4;
                    // console.log(dataItems);

                    //定义绘制图片
                    let img = document.getElementById("tulip");
                    let imgW = img.width * pixelRatio;
                    let imgH = img.height * pixelRatio;

                    labelCtx.strokeText(
                      text,
                      centerX - halfTxtWidth - Xoffset,
                      centerY + imgH / 2
                    );
                    labelCtx.hoverCursor = "pointer";
                    labelCtx.lineWidth = 0;
                    labelCtx.fillText(
                      text,
                      centerX - halfTxtWidth - Xoffset,
                      centerY + imgH / 2
                    );

                    labelCtx.drawImage(
                      img,
                      centerX - halfTxtWidth + Xoffset,
                      centerY - imgH,
                      imgW,
                      imgH
                    );
                    labelCtx.restore();
                    // labelCtx.canvas.drawImage('../../assets/icon/icon-point.png',centerX - halfTxtWidth, centerY);

                    // console.log(labelCtx);
                  }
                }
              });
              // 绘制点
              pointSimplifierIns = new PointSimplifier({
                map: _VueAMap, //所属的地图实例
                // autoSetFitView: true, //自动更新地图视野
                autoSetFitView: mapType, //禁止自动更新地图视野
                zIndex: 300,
                getPosition: function(item) {
                  if (!item) {
                    return null;
                  }

                  let parts = item.lngLatLine.split(",");

                  //返回经纬度
                  return [parseFloat(parts[0]), parseFloat(parts[1])];
                },
                //返回鼠标放上的文字
                // getHoverTitle: function(dataItem, idx) {
                getHoverTitle: function(dataItem) {
                  // console.log(idx);
                  // console.log(JSON.stringify(dataItem));
                  return dataItem.data.Title;
                },
                //使用GroupStyleRender
                renderConstructor:
                  PointSimplifier.Render.Canvas.GroupStyleRender,
                renderOptions: {
                  //点的样式
                  pointStyle: {
                    width: 6,
                    height: 6,
                    fillStyle: "rgba(153, 0, 153, 0.38)"
                  },
                  pointHoverStyle: {
                    content: "none"
                  },
                  pointHardcoreStyle: {
                    width: 0,
                    height: 0
                  },
                  getGroupId: function(item) {
                    return item.groupId;
                  },
                  groupStyleOptions: function(gid) {
                    // console.log(gid);
                    return groupStyleMap[gid];
                  },
                  //鼠标hover时的title信息
                  hoverTitleStyle: {
                    position: "right"
                  }
                }
              });

              //绘制聚合
              distCluster = new DistrictCluster({
                zIndex: 400,
                // autoSetFitView: true, //自动更新地图视野
                autoSetFitView: mapType, //禁止自动更新地图视野
                map: _VueAMap, //所属的地图实例
                getPosition: function(item) {
                  if (!item) {
                    return null;
                  }
                  let parts = item.lngLatLine.split(",");
                  //返回经纬度
                  return [parseFloat(parts[0]), parseFloat(parts[1])];
                },
                //使用自定义的引擎类
                renderConstructor: MyRender,
                renderOptions: {
                  //基础样式
                  featureStyle: {
                    fillStyle: "rgba(102,170,0,0)", //填充色
                    lineWidth: 0, //描边线宽
                    strokeStyle: "#aeaeae" //描边色
                  },
                  //特定区划级别的默认样式
                  featureStyleByLevel: {
                    //全国
                    country: {
                      fillStyle: "rgba(49, 163, 84, 0)"
                    },
                    //省
                    province: {
                      fillStyle: "rgba(116, 196, 118, 0)"
                    },
                    //市
                    city: {
                      fillStyle: "rgba(161, 217, 155, 0)"
                    },
                    //区县
                    district: {
                      fillStyle: "rgba(199, 233, 192, 0)"
                    }
                  },
                  //不再使用聚合标注
                  getClusterMarker: function() {
                    // getClusterMarker: function(feature,dataItems,recycledMarker) {
                    // if (dataItems.length > 0) {
                    //   console.log(feature, dataItems, recycledMarker);

                    //   var label = {
                    //     offset: new AMap.Pixel(16, 18), //修改label相对于marker的位置
                    //     content: `${feature.properties.name}(${dataItems.length})`
                    //   };

                    //   return new AMap.Marker({
                    //     icon: {
                    //       image: "./../../assets/icon/icon-point.png"
                    //     },
                    //     label: label
                    //   });
                    // } else {
                    return null;
                    // }
                  },
                  // 点击区划面后切换到子级区划
                  // featureClickToShowSub: false
                  featureClickToShowSub: true
                }
              });

              window.distCluster = distCluster;

              function refresh() {
                let zoom = _VueAMap.getZoom();
                //获取 pointStyle
                let pointStyle = pointSimplifierIns.getRenderOptions()
                  .pointStyle;
                //根据当前zoom调整点的尺寸
                pointStyle.width = pointStyle.height =
                  2 * Math.pow(1.2, _VueAMap.getZoom() - 8);
                // let zoom = _VueAMap.getZoom();
                console.log(zoom);
                if (zoom < 9) {
                  pointSimplifierIns.hide();
                  _VueAMap.clearInfoWindow();
                  distCluster.show();
                } else {
                  pointSimplifierIns.show();
                  distCluster.hide();
                }
              }

              _VueAMap.on("zoomend", function() {
                refresh();
              });

              refresh();

              //定义海量点图标库
              let dataArray = [];
              for (const i in _markers) {
                if (_markers.hasOwnProperty(i)) {
                  const el = _markers[i];
                  dataArray.push(el.Icon);
                }
              }
              dataArray = Array.from(new Set(dataArray));
              // console.log(dataArray);

              //根据所获取到的点图标分配组
              let _data = [];
              for (const key in _markers) {
                if (_markers.hasOwnProperty(key)) {
                  const _maerker = _markers[key];
                  for (const i in dataArray) {
                    if (dataArray.hasOwnProperty(i)) {
                      const el = dataArray[i];
                      if (el === _maerker.Icon) {
                        _data.push({
                          lngLatLine: `${_maerker.Longitude},${_maerker.Latitude}`,
                          groupId: i,
                          drawShadowPoint: true,
                          data: { ..._maerker }
                        });
                      }
                    }
                  }
                }
              }

              //循环定义海量点图标的绘制函数
              for (const i in dataArray) {
                if (dataArray.hasOwnProperty(i)) {
                  const el = dataArray[i];
                  // console.log(el);
                  groupStyleMap[`${i}`] = {
                    pointStyle: {
                      // 绘制点占据的矩形区域
                      content: PointSimplifier.Render.Canvas.getImageContent(
                        `${el}`,
                        // `http://huanbaoditu.com/${el}`,
                        function onload() {
                          pointSimplifierIns.renderLater();
                        },
                        function onerror() {
                          console.log("图片加载失败", el);
                          alert(`${el}图片加载失败！`);
                        }
                      ),
                      cursor: "pointer",
                      //宽度
                      width: 30,
                      //高度
                      height: 45,
                      //定位点为中心
                      offset: ["-50%", "-50%"],
                      fillStyle: null,
                      strokeStyle: null
                    }
                  };
                }
              }
              // console.log(groupStyleMap);

              that.newData = _data;
              //绘制海量点聚合
              distCluster.setData(that.newData);
              //绘制海量点
              pointSimplifierIns.setData(that.newData);
              //给海量点绑定事件

              //监听海量点的事件
              pointSimplifierIns.on(
                "pointClick pointMouseover pointMouseout",
                function(e, record) {
                  if (e.type === "pointClick") {
                    // console.log(e, record, that);
                    if (record.data.data) {
                      //TODO: 删除
                      // record.data.data.Type = 1;
                      let thatData = record.data.data;
                      // console.log(thatData, thatData.Type);
                      that.$http
                        .get("report/api/GetReportModel", {
                          params: {
                            ID: thatData.ID,
                            isview: 0,
                            Type: thatData.Type
                          }
                        })
                        .then(res => {
                          if (res.data.state) {
                            let imgUrl;
                            if (res.data.data.ImageFisrt !== undefined) {
                              imgUrl = res.data.data.ImageFisrt;
                            } else {
                              let imgs = res.data.data.reportImgs;
                              for (const i in imgs) {
                                if (imgs.hasOwnProperty(i)) {
                                  const el = imgs[i];
                                  if (el.Type === 1) {
                                    imgUrl = el.SmallImgUrl;
                                    break;
                                  }
                                }
                              }
                            }
                            let content = `
                                  <div id="newInfoWindow">
                                    <i class="icon icon-infoWindowClose" onclick="closeInfoWindow();"></i>
                                    <div class="img"><img src="${imgUrl}" /></div>
                                    <div class="cont">
                                      <i class="icon icon-infoWindowPoint"></i>
                                      <p class="_title">${res.data.data.Title}</p>
                                      <p class="font2">${res.data.data.Address}</p>
                                      <input id="viewInfo" onClick="viewInfo()" value="查看详情" type="button" />
                                    </div>
                                    <div class="bottom-angle"></div>
                                  </div>`;
                            let infoWindow = new AMap.InfoWindow({
                              isCustom: true,
                              anchor: "bottom-right",
                              content: content,
                              offset: new AMap.Pixel(72.5, -18)
                            });
                            infoWindow.open(
                              _VueAMap,
                              new AMap.LngLat(
                                thatData.Longitude,
                                thatData.Latitude
                              )
                            );
                            that.$emit(
                              "reportInfo",
                              res.data.data,
                              thatData.Type
                            );
                          }
                        })
                        .catch(error => {
                          console.log(error);
                        });
                    }
                  }
                }
              );
            }
            //加载相关组件
            AMapUI.load(
              [
                "ui/geo/DistrictCluster",
                "ui/misc/PointSimplifier",
                "lib/utils"
              ],
              function(DistrictCluster, PointSimplifier, utils) {
                //启动页面
                initPage(DistrictCluster, PointSimplifier, utils);
              }
            );
          })
        )
        .catch(e => {
          console.log(e);
        });
    }
  },
  watch: {
    markers(e) {
      if (e) {
        _markers = e;
        this.creatMap(this.mapType);
      }
    }
  }
};
</script>

<style lang="less">
#containerView {
  width: 100%;
  height: 100%;
  #container {
    width: 100%;
    height: 100%;
  }
  #newInfoWindow {
    position: relative;
    width: 365px;
    height: 95px;
    padding: 15px 13px;
    background: #fff;
    font-size: 0px;
    box-sizing: border-box;
    box-shadow: -2px 2px 5px #666;
    .icon.icon-infoWindowClose {
      position: absolute;
      right: 13px;
      top: 15px;
      z-index: 999;
      cursor: pointer;
    }
    * {
      box-sizing: border-box;
      position: relative;
    }
    .img,
    .cont {
      display: inline-block;
      vertical-align: text-top;
    }
    .img {
      width: 95px;
      height: 65px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .cont {
      width: 240px;
      height: 65px;
      padding-left: 30px;
      i.icon {
        position: absolute;
        top: 0px;
        left: 10px;
        transform: scale(0.8);
      }
      p {
        &._title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          color: #333333;
          padding-right: 20px;
        }
        &.font2 {
          color: #666;
          overflow: hidden;
          font-size: 12px;
          line-height: 180%;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      #viewInfo {
        color: #206ab0;
        background: none;
        border: 0;
        font-size: 12px;
        float: right;
        cursor: pointer;
        position: absolute;
        bottom: 0px;
        right: 0;
        background: #fff;
        padding: 5px 0 5px 18px;
      }
    }

    .bottom-angle {
      position: absolute;
      bottom: -8px;
      left: 80%;
      margin-left: -8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
      &::after {
        position: absolute;
        content: "";
        margin-left: -8px;
        margin-top: -7px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid rgba(0, 0, 0, 0.3);
        filter: blur(2px);
        z-index: -1;
      }
    }
  }
}
</style>
